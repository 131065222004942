/* ++++++++++
+++ PRINT +++
++++++++++ */

@import 'helpers/_colors';
@import 'helpers/_settings';

$font-sans: 'Trebuchet MS', 'Lucida Grande', Tahoma, sans-serif;
$font-serif: Georgia, Times, 'Times New Roman', serif;

// Add all elements that need hiding on this line:
#accesskey, .header, .breadcrumbs, .button, .btn, .footer, .header-nav, .menu-button, .metadata-share, .pagination, .rnav, .sidebar, .article-footer, .visually-hidden, .fluid-width-video-wrapper, .strp-window, iframe, form, input {
	display: none;
}

.header-logo-image {
	display: block;
	margin: 2em auto;
}

body {
    background: transparent;
    color: $black;
    font: $normal 12pt/1.3 $font-serif;
    -moz-font-feature-settings: 'liga', 'kern';
    -moz-font-feature-settings: 'liga=1, kern=1';
    -ms-font-feature-settings: 'liga', 'kern';
    -o-font-feature-settings: 'liga', 'kern';
    -webkit-font-feature-settings: 'liga', 'kern';
    font-feature-settings: 'liga', 'kern';
	width: 80%;
	margin: auto;
}

h1,h2,h3,h4,h5,h6 {
	font-family: $font-sans;
	font-weight: $bold;
	page-break-after:avoid;
}

h1{font-size:30pt;}
h2{font-size:25pt;}
h3{font-size:20pt;}
h4,h5,h6{font-size:18pt;}

// Lists
ol li {
	list-style: decimal;

	ol li { list-style: lower-roman; }
	ul li { list-style: circle; }
}

ul li {
	list-style: disc;

	ul li { list-style: circle; }
	ol li { list-style: decimal; }
}

// Media
img {
	width: auto;
	margin: auto;
	max-width: 100%;
}

figcaption {
	font-style: italic;
	text-align: center;
}

// Links
a {
	text-decoration: none;
	color: currentColor;
}

// Blockquotes
blockquote {
	border-left: 4px solid $secondary;
	font: italic $normal 16pt $font-serif;
	margin: $gap * 2 0;
	padding-left: $gap;
}

cite {
	color: $grey40;
	display: block;
	font: normal $normal 12pt $font-sans;
}

q {
	font-style: italic;
	quotes: "‘" "’" "“" "”";

	&:before {
		content: open-quote;
	}

	&:after {
		content: close-quote;
	}
}

table {
	border-spacing: 0;
	display: inline-block;
	max-width: 100%;
	vertical-align: top;
}

th, td {
	border: 1px solid $grey80;
	min-width: 140px;
	padding: $gap / 4 $gap / 2;
	text-align: left;
}

thead td,
tfoot td,
th {
	font-weight: $bold;
}

.metadata {
	color: $grey40;
	font: $font-sans;
}
